export const pcRouter = {
  home: '/pc/index',
  predict: '/pc/predict'
}

export const pcRouterList = [
  {
    path: '/pc',
    component: () => import('@/pc/layout/index.vue'),
    beforeEnter: () => {
      document.title = 'HiSeer'
    },
    children: [
      {
        path: 'index',
        component: () => import('@/pc/views/home/index.vue'),
        props: {
          bg: true // 是否显示背景图
        }
      },
      {
        path: 'predict',
        component: () => import('@/pc/views/predict/index.vue')
      }
    ]
  }
]
