const maxWidth = 414
const docEl = document.documentElement

function setRemUnit() {
  var rem =
    (window.screen.width > maxWidth ? maxWidth : window.screen.width) / 10
  docEl.style.fontSize = rem + 'px'
}

const pageshow = function (e) {
  if (e.persisted) {
    setRemUnit()
  }
}

function mountListener() {
  window.addEventListener('resize', setRemUnit)
  window.addEventListener('pageshow', pageshow)
}

function unmountListener() {
  window.removeEventListener('resize', setRemUnit)
  window.removeEventListener('pageshow', pageshow)
}

export default function () {
  return {
    setRemUnit,
    mountListener,
    unmountListener
  }
}
