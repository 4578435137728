<template>
  <Animation name="fade">
    <div class="history--wrapper" :style="style" v-if="visible">
      <div class="history-header">
        <van-icon
          class="left"
          name="arrow-left"
          :size="pxToPx(80)"
          @click="show({ value: false })"
        />
        <span class="title">{{ $t('menu.history') }}</span>
      </div>
      <div class="history-content">
        <div class="tab">
          <van-tabs
            :line-height="pxToPx(16)"
            :line-width="pxToPx(194)"
            v-model:active="activeTab"
          >
            <van-tab
              :title="tab.name"
              :name="tab.value"
              v-for="tab in tablist"
              :key="tab.name"
            >
            </van-tab>
          </van-tabs>
          <Animation name="left" mode="out-in">
            <component :is="list[activeTab]"></component>
          </Animation>
        </div>
      </div>
    </div>
  </Animation>
  <Order ref="orderRef" />
</template>
<script setup>
import Animation from '@/components/Animation.vue'
import Order from './Order.vue'
import ReportList from './ReportList.vue'
import PlateList from './PlateList.vue'
import AnswerList from './AnswerList.vue'
import { pxToPx } from '@/utils/pxToRem'
import { ref, computed, watch, getCurrentInstance, onUnmounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { ORDER_MENU } from '@/utils/contants'
import HexagramList from './HexagramList.vue'
import useSetUnit from '@/hooks/useSetUnit'

defineProps({
  style: {
    type: Object,
    default: () => ({})
  }
})

const { mitt } = getCurrentInstance().proxy
const { setRemUnit, mountListener, unmountListener } = useSetUnit()

const list = {
  birth: ReportList,
  plate: PlateList,
  answer: AnswerList,
  hexagram: HexagramList
}
const i18n = useI18n()
const route = useRoute()

const orderRef = ref()
const visible = ref(false)
const activeTab = ref('birth')
const tablist = computed(() => [
  {
    name: i18n.t('menu.talentReport'),
    value: 'birth'
  },
  {
    name: i18n.t('menu.plate'),
    value: 'plate'
  },
  {
    name: i18n.t('menu.answer'),
    value: 'answer'
  },
  {
    name: i18n.t('menu.hexagram'),
    value: 'hexagram'
  }
])

const show = (opt) => {
  const { value = true, historyType } = opt
  visible.value = value
  historyType && (activeTab.value = historyType)
  if (!value) {
    orderRef.value.show(false)
  }
}

const buryPoint = () => {
  if (activeTab.value === 'plate') gtag('event', 'history_bazi')
  else {
    gtag('event', 'history_report')
    gtag('event', 'historyrecords_view')
  }
}

const openOrderMenu = () => {
  orderRef.value.show()
}

mitt.on(ORDER_MENU, openOrderMenu)

onUnmounted(() => {
  mitt.off(ORDER_MENU, openOrderMenu)
})

watch([() => visible.value, () => activeTab.value], (watcher) => {
  const [visible] = watcher
  if (visible) {
    setRemUnit()
    buryPoint()
  } else {
    setTimeout(() => {
      if (/\/pc\//.test(route.path)) {
        document.documentElement.style.fontSize = '12px'
      }
    }, 500)
  }
})
defineExpose({ show })
</script>
<style lang="less" scoped>
@import '@/assets/style/variable.less';
.history--wrapper {
  position: fixed;
  // prettier-ignore
  top: 59Px;
  z-index: 3;
  width: 100%;
  max-width: @maxWidth;
  // prettier-ignore
  height: calc(100vh - 59Px);
  background-color: #fff;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  .history-header {
    width: 100%;
    max-width: @maxWidth;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    // align-items: center;
    height: 284px;
    width: 100%;
    text-align: center;
    .left {
      position: absolute;
      left: 60px;
      top: 50%;
      transform: translateY(-50%);
    }
    .title {
      font-size: 72px;
      font-weight: 500;
      letter-spacing: 2px;
    }
  }
  .history-content {
    width: 100%;
    max-width: @maxWidth;
    height: calc(100% - 284px);
    .tab {
      overflow-y: auto;
      height: 100%;
      :deep(.van-tabs__line) {
        background: #ffd04d;
        // width: 194px;
        // height: 16px;
        border-radius: 8px;
      }
      :deep(.van-tab) {
        font-size: 68px;
      }
      :deep(.van-tab--active) {
        color: #5193ff;
      }
    }
  }
  @media screen and (max-width: 759px) {
    height: calc(var(--vh) - @menuHeight) !important;
  }
}
</style>
