export default {
  you: '你',
  him: '他',
  her: '她',
  male: '男',
  female: '女',
  ming: '命',
  age: '歲',
  years: '年齡',
  sunStem: '日干',
  fate: '命局',
  name: '姓名',
  birth: '生日',
  // 命格
  destiny: ['印重局', '比肩局', '傷官局', '財旺局', '煞重局'],
  // 两仪
  opposite: {
    black: '陰',
    white: '陽'
  },
  // 五行
  fiveEl: {
    gold: '金',
    wood: '木',
    water: '水',
    fire: '火',
    earth: '土'
  },
  // 天干
  // prettier-ignore
  stem: {
    '甲': '甲',
    '乙': '乙',
    '丙': '丙',
    '丁': '丁',
    '戊': '戊',
    '己': '己',
    '庚': '庚',
    '辛': '辛',
    '壬': '壬',
    '癸': '癸'
  },
  // 地支
  // prettier-ignore
  branch: {
    '子': '子',
    '丑': '丑',
    '寅': '寅',
    '卯': '卯',
    '辰': '辰',
    '巳': '巳',
    '午': '午',
    '未': '未',
    '申': '申',
    '酉': '酉',
    '戌': '戌',
    '亥': '亥'
  },
  // 二十四节气
  twenty_four: {
    lichun: '立春',
    yushui: '雨水',
    jingzhe: '驚蟄',
    chunfen: '春分',
    qingming: '清明',
    guyu: '穀雨',

    lixia: '立夏',
    xiaoman: '小滿',
    mangzhong: '芒種',
    xiazhi: '夏至',
    xiaoshu: '小暑',
    dashu: '大暑',

    liqiu: '立秋',
    chushu: '處暑',
    bailu: '白露',
    qiufen: '秋分',
    hanlu: '寒露',
    shuangjiang: '霜降',

    lidong: '立冬',
    xiaoxue: '小雪',
    daxue: '大雪',
    dongzhi: '冬至',
    xiaohan: '小寒',
    dahan: '大寒'
  },
  // 日期
  time: '時辰',
  unknow: '不明',
  extra: '閏',
  solar: '公曆',
  lunar: '農曆',
  year: '年',
  month: '月',
  day: '日',
  hour: '時',
  minute: '分',
  second: '秒',
  about: '關於HiSeer',
  // 公共按钮
  copy: '複製',
  iknow: '知道了',
  save: '保存',
  no_save: '不保存',
  confirm: '確定',
  cancel: '取消',
  submit: '提交',
  prompt: '提示',
  download: '下載',
  to_download: '去下載',
  search: '査詢',
  estimate: '立即測算',
  unlock: '立即解鎖',
  check_report: '立即查看天賦',
  birth_report: '生日報告',
  cal_name: '測算者姓名',
  orderno: '訂單號',
  order_tips: '訂單號可用於査詢歷史報告，請妥善保存',
  title: 'HiSeer-你的專屬運勢專家',
  title_birth: 'HiSeer-報告預測',
  title_plate: 'HiSeer-免費排盤',
  birth_desc: '剛剛發現一個超准的測試，快來試試吧！',
  plate_desc: '剛剛發現一個超好用的排盤工具，快來試試吧！',
  click_right: '點擊右鍵保存圖片',
  // 评价
  rate: {
    rate: '評價',
    place: '展開說說您的想法吧…',
    rate_q: '您認為哪些方面有待提高？',
    advices: [
      '內容不够豐富',
      '產出速度太慢',
      '價格不够親民',
      '預測不够準確',
      '其他（請填寫）'
    ]
  },
  nav: {
    title: 'HiSeer-獨家預測',
    button: '打開APP'
  },
  menu: {
    project: '預測項目',
    talentReport: '報告',
    plate: '免費排盤',
    answer: '答案之書',
    type: '報告類型',
    entire: '全能報告',
    person: '人格報告',
    destiny: '命運報告',
    love: '戀愛報告',
    profession: '職業報告',
    relation: '人際報告',
    match: '合盤報告',
    annual: '年度運勢報告',
    enterprise: '企業智慧人才測評',
    annual_custom: '年度運勢 定制報告',
    hexagram: '問卦諮詢',
    career: '職業發展測評',
    history: '歷史記錄',
    order_search: '订单查询',
    privacyArgeement: '隱私政策',
    userArgeement: '用戶協議',
    contact: '聯繫客服',
    home: '全能報告預測',
    result: '全能報告結果',
    help: '如需幫助、易學諮詢請聯系微信或郵箱',
    pay_tips: '使用無痕瀏覽器進行微信支付可能導致异常',
    wechat: '微信號：',
    email: '郵箱：',
    birth_name: '測算者姓名',
    order_type: '訂單類型',
    order_name: '訂單內容',
    theme: '主題',
    operate: '操作',
    report: '報告',
    plate_name: '姓名',
    gender: '性別',
    birth: '出生時間',
    detail: '查看詳情',
    no_history: '暫無記錄',
    no_order: '這裡沒有我的訂單？',
    no_result: '未搜索到相關記錄',
    order_tips: '輸入訂單號/已綁定的手機號或郵箱進行査詢'
  },
  time_picker: {
    toast: '僅支持1900年至今日的生日',
    nohour: '時辰不明',
    title: '選擇時辰範圍',
    place: '選擇時辰',
    unknow: '時辰未知',
    error: '請輸入正確的生日',
    change: '節氣變化',
    y_range: '年輸入範圍',
    m_range: '月輸入範圍1-12',
    d_range: '日輸入範圍',
    hour_err: '請選擇時辰',
    clear: '清除',
    delete: '刪除'
  },
  payment: {
    only: '僅需',
    price: '原價',
    icon: '￥',
    before_price: '立即解鎖',
    after_price: '全能報告',
    origin_price: '299TWD',
    sale: '139TWD',
    pay: '微信支付',
    fail: '支付失敗',
    title: '的全能報告',
    type_item_0: {
      title: '本命解讀',
      improve: '個人提升',
      text_0: '最强天賦',
      text_1: '性格思維'
    },
    type_item_1: {
      title: '人際交往',
      improve: '人脈提升',
      text_0: '六親關係',
      text_1: '貴人分析'
    },
    type_item_2: {
      title: '職業規劃',
      improve: '事業提升',
      text_0: '事業觀念',
      text_1: '職業能力'
    },
    type_item_3: {
      title: '戀愛指南',
      improve: '情感提升',
      text_0: '情感觀點',
      text_1: '最佳交往對象'
    },
    order_confirm: '訂單確認中...',
    try_later: '請稍後重試',
    slogan_0: '把握天賦',
    slogan_1: '精准分析',
    slogan_2: '改變命運',
    sale_text: '限時優惠價：',
    until_end: '距離優惠結束還有',
    people_paid: '人購買了報告',
    useful: '的人覺得有用',
    current_order: '分鐘前購買了報告',
    user_title: '請確認支付是否完成',
    user_complete: '已完成支付',
    user_again: '支付遇到問題，重新支付',
    notify_continue: '繼續支付',
    notify_cancel: '取消',
    fans_price: '粉絲專享價：',
    fans_sale: '限首次免費',
    fan_btn: '立即查看',
    free_btn: '領取免費報告',
    stripe: '信用卡/借記卡支付',
    contact: '聯繫\n客服',
    save_modal: {
      button: '領取免費報告',
      title: '離免費領取報告只剩一步\n驗證手機號或郵箱',
      error: '每人只有1次免費領取機會哦！',
      prompt: '提示',
      tip: '離領取報告只剩一步，您確定要放弃粉絲專享免費報告嗎？',
      success: '領取成功',
      success_h5: '關閉彈窗即可查看報告',
      success_txt:
        '打開HiSeer app，使用驗證時輸入的郵箱或手機號登入，可查看已領取的報告',
      open_app: '打開app查看報告',
      continue: '繼續領取',
      cancel: '狠心放弃',
      outdated: '活動已結束'
    }
  },
  share: {
    card: '的專屬名片',
    qr: '掃碼獲取&全能報告',
    copy_url: '剛剛獲得了我的專屬名片，快來看看>>',
    copy_success: '連結已複製，快去分享吧',
    share_card: '點擊分享名片',
    check_mine: '查看我的全能報告',
    earth: '地球上',
    talent_first: '的人與其擁有類似的天賦，',
    talent_rest: '是其中之一哦~',
    save_tip: '長按保存圖片'
  },
  // 打开app页面
  open_app: {
    gift: '送你一張專題生日報告免費體驗券',
    receive: '在HiSeer app中輸入我的邀請碼即可領取哦！！',
    coupon: '生日報告免費體驗券',
    code: '我的邀請碼',
    copyed: ' 邀請碼已複製',
    tips: '點擊右上角在瀏覽器打開',
    loading: '正在載入'
  },
  // pc页面
  pc: {
    banner: {
      title: 'HiSeer-你的專屬運勢專家',
      desc: '一款功能全面、專業可靠的玄學占卜軟件，讓你隨時隨地掌握命運運勢，提升生活智慧。'
    },
    part_1: {
      bold: '【每日運勢】',
      desc: '精准分析財運、情感、工作、學業、出行、人際、健康等全方位運勢'
    },
    part_2: {
      bold: '【獨家預測報告】',
      desc: '解鎖生日密碼，提升天賦認知\n樣合盤報告，揭秘雙方關係'
    },
    part_3: {
      bold: '【1對1專業諮詢】',
      desc: 'Hiseer專業命理諮詢師提供涵蓋命盤詳批、運勢解讀、六爻起卦、擇日、取名、面相等多樣化服務'
    },
    part_4: {
      bold: '【玄學資訊與學習資源】',
      desc: '獨立的自媒體團隊，開啟豐富、有趣的玄學話題'
    },
    download: '下載HiSeer',
    introduce: '產品介紹',
    predict: '線上預測',
    love: '愛情合盤報告',
    career: '職業發展測評報告',
    statement: '協定聲明',
    privacyArgeement: '隱私政策',
    line_tips: '通過二維碼添加LINE好友',
    add_by_line: '點擊添加LINE',
    wechat_tips: '通過二維碼添加微信好友',
    face_home: 'Facebook主頁',
    open_home: '掃描二維碼打開主頁',
    go_face: '前往主頁',
    ins_home: 'Instagram主頁',
    report_title: 'HiSeer 預測報告',
    annual_desc: '大師1v1撰寫/超准預測/趁早規劃/好運加持',
    match_desc: '揭秘關係本質 預測愛情走向',
    enterprise_desc: '全方位智慧評估 助您慧眼識良才',
    plan_desc: '量身定制職業規劃 工作發展快人一步',
    download_txt: '下載HiSeer App\n體驗更多獨家預測功能',
    huawei: '華為手機下載',
    xiaomi: '小米手機下載',
    vivo: 'vivo手機下載',
    oppo: 'oppo手機下載',
    honor: '榮耀手機下載',
    other: '其它安卓手機下載',
    toast: {
      email: '郵箱已復製',
      wechat: '微信號已復製'
    }
  },
  front: {
    title: 'HiSeer 預測報告',
    title_dload: 'HiSeer APP下載',
    title_plate: 'HiSeer 獨家預測',
    hexagram: '問卦諮詢',
    hexagram_desc: '一事一占 大師解卦',
    plate: '八字排盤',
    answer: '答案之書',
    answer_desc: '海量問題 專業解答',
    hiseer: '更多玄學知識、預測報告\n盡在HiSeer App',
    contact: '聯繫方式',
    contact_tip: '真人諮詢、商務合作、請聯系',
    email: '郵箱',
    wechat: 'wechat',
    facebook: '每日运势测算',
    annual_activity: '下單贈開運符',
    annual_swiper: '諮詢師線上 實时解答',
    annual_bold: '1對1諮詢',
    planning: '個人規劃',
    route: {
      reports: ['人格', '命運', '戀愛', '職業', '人際'],
      five_in_one: '報告五合一',
      entire: '全能報告',
      big_txt: '超值',
      person: '人格報告',
      person_desc: '帶你尋找隱藏天賦',
      destiny: '命運報告',
      destiny_desc: '帶你尋找相似名人',
      love: '戀愛報告',
      love_desc: '帶你尋找靈魂伴侶',
      relation: '人際報告',
      relation_desc: '帶你尋找命中貴人',
      profession: '職業報告',
      profession_desc: '助你尋找心儀事業'
    }
  },
  home: {
    history_order: '歷史&訂單',
    report_title: '全能報告為你解讀生日密碼，提供提升建議',
    option_0: '生辰八字排盤',
    option_1: '最强天赋解析',
    option_2: '典型名家名人',
    option_3: '性格思維特點',
    option_4: '潜力提升建議',
    option_5: '六親貴人關係',
    option_6: '職業規劃建議',
    option_7: '情感戀愛觀點',
    title: '填寫信息以生成全能報告',
    form: {
      title: {
        self: '是否本人',
        birth: '生日',
        gender: '性别',
        name: '姓名'
      },
      birth_placeholder: '請輸入出生日期',
      name_placeholder: '請輸入姓名',
      name_err: '最多輸入20字元',
      option: {
        self_one: '本人',
        self_tow: '非本人',
        male: '男',
        female: '女'
      }
    },
    history: '選擇八字排盤中的記錄',
    argeement: '使用服務表示同意<a>用戶協議</a>',
    button: '立即查看全能報告',
    share: '邀請朋友測',
    copy_share: '剛剛發現一個超准的報告，快來試試吧！',
    talent: {
      question_1: '全能報告是什麼？',
      answer_1:
        '每個人在出生的那一刻，宇宙就給生命注入了能量，能量的差异造就了不同的個體，所以我們每個人都擁有不同的人格、天賦、外貌和社會關係，從而擁有不同的命運。<br/>全能報告基於生辰時間，從人格和天賦出發，全方位、多角度為你解讀生日密碼中的生命信息，助你走好人生路。',
      // answer_1:
      //   '每個人的<strong>天賦</strong>都是上天特別量身定制的禮物，正是天賦中包含的<strong>智慧與力量</strong>幫助我們<strong>披荊斬棘，走好人生之路</strong>。',
      question_2: '決定天賦的要素是什麼？',
      answer_2:
        '每個人的生日生時都由天而定，不可預測、不可更改，而每個人的<strong>天賦</strong>如何就藏在<strong>生日密碼</strong>中。',
      question_3:
        '你是否對自己/他/她的認知有很多疑惑？<br/>你是否想從生日密碼中更加瞭解自己？',
      answer_3:
        '如果你也有同樣的疑惑，就和HiSeer易學大師一起來揭開“生日密碼”的神秘面紗，從命盤角度來看看你我他/她的命運走向。',
      unlock_count: '20000+用戶已解鎖報告',
      report_rate: '報告評價',
      accuracy: '準確率',
      usable: '實用性',
      satisfaction: '滿意度'
    },
    // swiper
    swiper: {
      first: {
        title: '通過《全能報告》，你可以獲得：',
        desc: {
          title_1: '通過命盤分析全方位、多角度解讀個人天賦：',
          list_1: `優缺點關鍵字，勾勒獨特性格
          個人最强天賦的具體表現
          價值觀分析、性格分析、思維特點分析
          同類型名人名家，展望未來發展`,
          title_2: '從三個方面切入，解析個人發展：',
          list_2: `與他人的社交關係遠近情况分析
          戀愛表現分析
          職業能力、長短板分析`,
          title_3:
            '運用命盤分析天賦中的優劣勢，並對此提出潛力提升建議、社交注意事項、最佳戀愛對象以及從業建議'
        },
        option_1: '發揮天賦優勢',
        option_2: '改變自我認知',
        option_3: '找到發展方嚮',
        option_4: '彌補職業短板',
        option_5: '挖掘提升潛力'
      },
      second: {
        title: '報告依據',
        first_para: `<strong>《全能報告》</strong>是由<span>HiSeer</span>資深易學研發團隊和上清·碧海命理研修院共同研發推出的基於八字命理、針對個人天賦以及內在思維意識和行為特點的深度剖析解讀報告。`,
        second_para:
          '我們的命理研修團隊將傳統易學進行了進一步的精煉和優化，與心理學、哲學、大數據以及當代社會文化相結合，從每個人獨有的八字命盤出發，深度分析個人一生以及階段性的天賦意識與價值取嚮，為用戶的生活指點迷津。'
      },
      example: '報告示例',
      fifth: {
        title: '用戶感受',
        comment_1:
          '出於好奇試了試全能報告，有一段描述簡直就是給我裝監控才能寫出來的，這也太神奇了吧！ 最强天賦的描述給了我一些信心，謝謝大師！',
        date_1: '2022/12/16',
        comment_2:
          '我的全能報告 好可愛hhh，所以朋友老是說我太感性是事實嗎，希望可以根據建議努力變得更好，加油！',
        date_2: '2023/01/01',
        comment_3: '看完啦，寫我在感情裡的狀態真的太准了555',
        date_3: '2023/01/05',
        comment_4:
          '最近孩子高考報志願，想拿這個看看做個參考，性格方面的描述比較準確，他確實比較嚴謹，根據從業建議選擇了電腦專業，希望可以被錄取。',
        date_4: '2022/06/25  14:30'
      }
    },
    our_team: {
      title: '我們的團隊',
      tip_1: '擁有40余年易學研究基礎',
      tip_2: '從事易學教育十二年',
      tip_3: '已培養3000+易學愛好者',
      tip_4: '完成35萬例線下1v1諮詢服務',
      tip_5: '建立百萬級命理八字數據庫',
      hiseer: 'HiSeer 出品'
    },
    // 引流模块
    advertise: {
      slogan_item_1: '每日運勢播報',
      slogan_item_2: '八字排盤',
      slogan_item_3: '手相預測',
      slogan_item_4: '问卦咨询',
      slogan_item_5: '預測報告',
      slogan_item_6: '答案之書預測',
      google_download: 'Android下載',
      ios_download: 'Apple下載',
      hiseer: 'HiSeer\n你的專屬運勢專家'
    },
    // 底部按钮
    footer_btn: '生成全能報告'
  },
  generating: {
    title: '報告生成中',
    birth: {
      step: [
        '生日密碼解析中',
        '本命解讀生成中',
        '人際交往分析中',
        '職業規劃分析中',
        '戀愛指南生成中'
      ],
      complete: [
        '生日密碼解析完成',
        '本命解讀生成完成',
        '人際交往分析完成',
        '職業規劃分析完成',
        '戀愛指南生成完成'
      ]
    },
    person: {
      step: [
        '天賦類型匹配中',
        '關鍵字査詢中',
        '最强天賦解讀中',
        '性格特點分析中',
        '潜力提升建議生成中'
      ],
      complete: [
        '天賦類型匹配完成',
        '關鍵字査詢完成',
        '最强天賦解讀完成',
        '思維特點對比中',
        '潜力提升建議生成完成'
      ]
    },
    destiny: {
      step: [
        '天賦類型匹配中',
        '相似名人檢索中',
        '價值觀解讀中',
        '思维特点对比中',
        '潜力提升建議生成中'
      ],
      complete: [
        '天賦類型匹配完成',
        '相似名人檢索完成',
        '價值觀解讀完成',
        '思維特點對比完成',
        '潜力提升建議生成完成'
      ]
    },
    love: {
      step: ['天賦類型匹配中', '戀愛性格分析中', '最佳對象匹配中'],
      complete: ['天賦類型匹配完成', '戀愛性格分析完成', '最佳對象匹配完成']
    },
    relation: {
      step: '貴人檢索中',
      complete: '貴人檢索完成'
    },
    profession: {
      step: '職業性格分析中',
      complete: '職業性格分析完成'
    },
    yin0: '邏輯超強的&理想主義者',
    yin1: '獨立思考的&真理探索家',
    bi0: '熱情仗義的&能力擔當者',
    bi1: '果敢陽光的&人生冒險家',
    shang0: '天馬行空的&浪漫智多星',
    shang1: '腦洞大開的&藝術生活家',
    cai0: '實事求是的&價值創造者',
    cai1: '勤勉務實的&社會貢獻者',
    sha0: '工匠精神的&完美主義者',
    sha1: '自律嚴謹的&秩序守護者'
  },
  report: {
    share: '邀請朋友測',
    save_card: '生成專屬名片',
    tablist: {
      life: '本命解讀',
      interpersonal: '人際交往',
      career: '職業規劃',
      love: '戀愛指南'
    },
    life: {
      unknow: '時辰不明',
      talent_type: '的天賦類型是：',
      key_word_title: '解讀關鍵字',
      advantage: '優點',
      shortcomings: '缺點',
      // 最强天赋
      most_best_talent: '最強天賦',
      // 典型名家名人
      famous_person: '典型名家名人',
      famous_person_from: '以上資料來源於《維基百科》',
      // 价值观
      values: '價值觀',
      values_decs_title: '<strong>縱觀一生</strong>，',
      values_decs_title_rest: '的價值觀構成是什麼樣的？',
      values_all_life: '一生的價值觀',
      values_now: '現階段的價值觀',
      // 性格分析
      personality: '性格分析',
      highlight: '閃光點',
      short: '弱點',
      // 思维特点
      thinking: '思維特點',
      thinking_all_life: '一生的思維特點',
      thinking_now: '現階段的思維特點',
      // 潜力提升建议
      potential: '潛力提升建議'
    },
    interpersonal: {
      // 六亲关联性排名
      interpersonal: '人際交往',
      relative: '六親關聯性排名',
      life_relative: '一生的六親關聯性',
      now_relative: '現階段的六親關聯性',
      all_life: '縱觀一生',
      now_period: '現階段',
      relative_extent: '的六親關聯程度：',
      father: '父親',
      husband: '丈夫/男友',
      child: '孩子',
      sis_bro_fir: '兄弟姐妹/朋友',
      mother: '母親',
      wife: '妻子/女友',
      year_period: '2013年~2023年',
      relative_content_txt:
        '這裡的關聯性排名具體體現在日常生活中六親對你的影響程度、你對他們的關注程度以及與其交往過程中花費時間/精力的多少，排名越靠前代表影響力越大、關注程度越高、花費時間精力越多。',
      // 生命中的贵人
      elegant: '生命中的貴人',
      relative_elegant: '六親中的貴人',
      elegant_analysis: '從人物類型角度分析，你的貴人包括：'
    },
    career: {
      career: '職業規劃',
      // 事业观
      career_outlook: '事業觀',
      nature: '職業性格',
      //职业能力分布
      abilities: '职业能力分布',
      // 从业建议
      career_advice: '從業建議',
      low: '弱項',
      high: '强項',
      normal: '平均'
    },
    love: {
      // 爱情观
      love_outlook: '愛情觀',
      // 最佳交往对象
      best_lover: '最佳交往對象'
    },
    rate: {
      txt: '預測體驗如何？ 評價一下吧～',
      completed: '我的評價',
      rate_please: '喜歡的話就給報告點亮5顆星吧～',
      advice: '您給HiSeer的建議（選填）',
      score: '分',
      advice_placeholder: '希望看到更多哪方面的內容或服務…',
      toast: '請選擇評分',
      success: '感謝您的評價！'
    },
    save_tip: '當前報告未保存，現在離開可能會遺失該報告，是否前往保存？',

    save: {
      save_report: '保存報告',
      title: '保存報告到HiSeer App',
      save2seer: '手機號驗證',
      save2email: '郵箱驗證',
      seer_tips: '验证手机号成为HiSeer用户，直接保存报告至HiSeer app',
      email_tips: '接收郵箱地址',
      placeholder_code: '請輸入驗證碼',
      placeholder_email: '請輸入您的郵箱',
      placeholder_phone: '請輸入手機號',
      send_code: '发送驗證碼',
      send_again: '重發',
      code_has_been_sent: '驗證碼已發送',
      report2seer: '保存報告到HiSeer App',
      report2email: '保存報告至郵箱',
      phone_err: '請輸入正確的手機號',
      email_err: '請輸入正確的郵箱',
      code_err: '驗證碼錯誤',
      send_success: '保存成功',
      save_success:
        '打開HiSeer app，\n使用驗證時輸入的郵箱或手機號登入，\n可查看已領取的報告',
      email_verify: '郵箱驗證',
      email_code_success: '驗證碼已發送至郵箱，請輸入驗證碼',
      email_modal_code_tip: '（如未收到請查看垃圾郵件）',
      email_tip: '為了保護您的信息安全，驗證郵箱後結果將自動保存',
      prompt: '提示',
      prompt_content:
        '不保存將會新增結果遺失的風險，保存後您可以使用驗證的帳號登入HiSeer app查看結果，是否保存？',
      tip: '請仔細記錄您填寫的帳號，\n後續您可登入HiSeer app查看報告',
      modal_success: [
        '1.打開HiSeer app，使用保存時輸入的郵箱或手機號登入，可查看已保存結果',
        '2.點擊本網頁右上角<span>☰</span>按鈕，在歷史記錄-訂單查詢頁面輸入訂單號/保存時提供的手機號或郵箱進行訂單查詢，可查看已保存結果'
      ]
    },
    // 查看更多天赋报告
    whole_report: {
      title: '全能報告完整內容',
      description: '針對每個用戶進行四個層面，共12個角度的深度全面分析預測。'
    },
    filter: {
      unlock: '解鎖完整報告'
    }
  },
  // 人格指南
  personality: {
    desc_list: [
      '生辰八字排盤',
      '人格類型匹配',
      '最强天賦挖掘',
      '性格觀念分析',
      '潜力提升建議'
    ],
    description_title: '看見隱藏的天賦，挖掘潜在的能力',
    description:
      '“我只是來人間湊數的無名之輩”\n不！ 你只是沒有發現自己的天賦！\n每個人都有與生俱來的特質，但與生俱來也意味著很難自己察覺，囙此它就像巨大的寶藏，正在靜靜地等著探尋和發掘。',
    match: {
      title: '20種人格類型精准匹配',
      type0: '冒險家',
      desc0: '慷慨重義',
      desc_png0: '聰明靈活',
      type1: '理想家',
      desc1: '聰明仁慈',
      desc_png1: '淡泊名利',
      type2: '貢獻者',
      desc2: '待人寬厚',
      desc_png2: '善解人意',
      type3: '腦洞家',
      desc3: '才華橫溢',
      desc_png3: '桀驁不馴'
    },
    keyword: {
      title: '優缺點關鍵字全面解讀',
      merit: '優點',
      merits: ['智慧', '責任心', '有條理', '誠實', '有計劃', '重承諾'],
      defect: '缺點',
      defects: ['傳統', '固執', '猶豫不決', '過度分析', '優柔寡斷', '保守']
    },
    talent: {
      title: '天賦能力多角度分析',
      list: ['嚴謹', '創意', '規劃', '落地', '协作']
    },
    nature: {
      title: '性格長短板深度分析',
      list: [
        '戰略思考',
        '意志力强大',
        '富於常識和悟性',
        '不敏感',
        '傲慢',
        '遲鈍和強勢'
      ]
    },
    advice: {
      title: '專業建議幫助潜力提升',
      list: ['關注情緒', '重視他人價值', '提前思考']
    },
    person_report:
      '本測算基於易理算灋，通過分析姓名、出生時間、性別等資訊結合現實情况，分析測試者的天賦、性格、優缺點，針對個人特點提供潜力提升指南。',
    more: {
      title: '更多報告',
      click: '點擊查看報告詳情'
    },
    comment_list: [
      {
        time: '2023/03/25',
        text: '非常准，之前測過MBTI感覺講得比較模糊，這個人格報告描述得比較細緻，幫我提升了自我認知'
      },
      {
        time: '2023/03/31',
        text: '很好，分析了我的隱藏天賦，給了我不少信心'
      },
      {
        time: '2023/01/08',
        text: '哈哈隨便測測沒想到講得這麼仔細，保存了，以後迷茫的時候拿出來看看'
      }
    ],
    fixed_btn: '立即查看天賦'
  },
  // 命运指南
  destiny_report: {
    desc_list: ['名人匹配', '人格定位', '觀念解讀', '思维分析', '潜力提升'],
    description_title: '你可以像名人一樣成功',
    description:
      '哪些名人與你屬於同一種命運格局？\n誰和你擁有相似的天賦/性格特點？\n我們為你精准匹配與你命運格局相同的名人，幫助你更瞭解自己的發展方向、潜在天賦\n瞭解你們之間的共同特點，他們可能就是你的未來…',
    celebrity: {
      title: '匹配與你天賦命運相似的名人',
      desc: '尋八字命運相似之人\n發掘自己的終極潜力'
    },
    value: {
      title: '價值觀全面解讀',
      list: ['理想主義', '完美主義', '團隊精神', '務實精神', '自由主義']
    },
    thinking: {
      title: '思維特點橫縱向對比',
      list: ['邏輯思維', '主觀能動', '嚴謹自控', '創意靈感', '務實落地']
    },
    comment_list: [
      {
        time: '2023/03/17',
        text: '與我最相似的名人第一個竟然是Lisa！！！ 開心！！！！'
      },
      {
        time: '2023/02/03',
        text: '潜力提升建議給得很實用，贊哦~'
      },
      {
        time: '2023/01/05',
        text: '我確實是比較嚴謹自控的人，分析得還是挺可靠的，希望真的能像相似名人那樣獲得成功啊'
      }
    ],
    description:
      '本測算基於易理算灋，通過分析姓名、出生時間、性別等資訊結合現實情况，將測試者的人格、思維、觀念與千位名人作對比，匹配與測試者最相似的名人，並針對個人特點提供潜力提升指南。'
  },
  // 恋爱指南
  love_report: {
    head_list: ['人格匹配', '戀愛分析', '男方助妻', '女方助夫'],
    description_title: '指引你走向幸福和諧的愛情',
    description:
      '戀愛是一場勢均力敵的博弈，雙方都有著自己的獨立人格和精神世界，既不能完全依附對方，也不能讓對方完全佔據自己。\n從現在開始，强化自己的人格認知，正視自己的戀愛觀念，與伴侶建立健康和諧的戀愛關係，共同進步、相互成就，攜手徜徉天地間。',
    analysis: {
      title: '恋爱性格观念分析',
      list: [
        '<span>客觀分析你的戀愛感受</span>，幫助你更清楚地瞭解你和伴侶間的深層情感',
        '<span>深入解讀你的戀愛性格</span>，幫助你有效掌握戀愛中的積極力量，擴大你的戀愛世界'
      ]
    },
    match: {
      title: '匹配最適合你的另一半',
      radio: '匹配度',
      list: [
        '果敢陽光的\n人生冒險家',
        '邏輯超強的\n理想主義者',
        '自律嚴謹的\n秩序守護者',
        '工匠精神的\n完美主義者'
      ]
    },
    comment_list: [
      {
        time: '2023/03/29',
        text: '說我的最佳交往對象是邏輯思維能力很强的人誒，剛好我身邊就有一個，希望能修成正果~'
      },
      {
        time: '2023/03/01',
        text: '准的誒，我在戀愛方面… 確實很主動哈哈哈，希望早點脫單！'
      },
      {
        time: '2023/02/11',
        text: '交往過的三個對象都挺符合描述的，讓我早點遇到對的他吧'
      }
    ],
    report_des:
      '本測算基於易理算灋，通過分析姓名、出生時間、性別等資訊結合現實情况，分析測試者情感觀念和戀愛角色，給予實際可行的建議，幫助找到順旺好姻緣。'
  },
  // 人际指南
  relation: {
    header_list: ['看六親關係親疏', '教你慧眼識貴人'],
    description_title: '教你練就識別貴人的眼力',
    description:
      '貴人不在於有多“貴”，而在於有多“近”\n從六親中的貴人開始，讓貴人主動走近你！\n讓你的每一天你都遇見貴人，每一件事都有貴人幫助。',
    relatives: {
      title: '六親關係分析',
      list: ['子', '姐妹', '兄弟', '父', '母', '妻', '夫', '女']
    },
    analysis: {
      title: '貴人分析',
      content:
        '六親中，誰是你的貴人？\n社會上，誰是你的貴人？\n貴人秘笈在手榮華富貴都有'
    },
    comment_list: [
      {
        time: '2023/03/10',
        text: '好准啊，我就是“媽寶女”，跟媽媽關係超好'
      },
      {
        time: '2023/02/22',
        text: '我很喜歡交朋友，原來是因為他們是我的貴人哈哈哈'
      },
      {
        time: '2023/02/05',
        text: '建議我多跟邏輯性强的人交流，確實是，我自己邏輯性比較差哈哈哈'
      }
    ],
    report_desc:
      '本測算基於易理算灋，通過分析姓名、出生時間、性別等資訊結合現實情况，分析測試者的家庭六親關係，針對個人特點提供貴人交往指南，助力測試者多交貴人、步步高升。'
  },
  // 职场指南
  profession: {
    list: ['職業性格分析', '職業能力評估', '職業選擇指南'],
    description_title: '一步到位搞定職業規劃',
    description:
      '適合自己的才是最好的，選對職業比努力更重要！\n“職業指南”幫助您更全面瞭解自己的性格，搞清楚自己的優勢和劣勢，找到與自身特點最匹配的職業，拓展職業發展的視野，實現職業生涯的長遠規劃。',
    profession_analysis: {
      title: '職業性格分析',
      list: ['認識自己的職業性格', '改變自己的職場命運！']
    },
    ability: {
      title: '職業能力分佈指數',
      left_list: ['戰略佈局', '包裝文宣', '市場需求', '成本减耗', '獨立研發'],
      right_list: ['科技綜合', '公關社交', '原創設計', '盈利擴大', '科技綜合']
    },
    advice: {
      title: '職業類型建議',
      list: [
        '資訊傳播',
        '作家/記者',
        '企業商業',
        '顧問/銷售',
        '教育/諮詢',
        '翻譯/大學教授',
        '科技/互聯網',
        '軟體發展工程師\n網路系統管理員',
        '政府/服務',
        '消防員\n警察',
        '專業性職業',
        '律師\n法官'
      ]
    },
    comment_list: [
      {
        time: '2023/03/19',
        text: '文理分科選擇困難症，烦乱了半天，沒想到一個報告比我自己還瞭解我自己，希望分班順利'
      },
      {
        time: '2023/03/22',
        text: '准畢業生，來看看自己適合幹哪行，感覺報告裡面最有價值的是職業能力分佈，很專業'
      },
      {
        time: '2023/02/15',
        text: '求職迷茫期看到這個報告，很及時很nice'
      }
    ],
    profession_description:
      '本測算基於易理算灋，通過分析姓名、出生時間、性別等資訊結合現實情况，分析測試者的職業性格、工作能力並為其提供恰當的職業建議。'
  },
  // 合盘报告
  match: {
    select_type: '請選擇合盤類型',
    homo: '同性愛情合盤',
    hetero: '男女愛情合盤',
    form_desc: '揭秘關係本質 預測愛情走向',
    your_info: '你的資訊',
    his_info: '對方的資訊',
    match_now: '立即合盤',
    relative: '當前關係：',
    select_relative: '選擇當前關係',
    relatives: [
      { label: '曖昧', value: 1 },
      { label: '熱戀', value: 2 },
      { label: '婚後', value: 3 }
    ],
    puzzle: {
      title: '面對“愛情”，你有這些困惑嗎？',
      help: '愛情合盤報告可以幫助你',
      puzzle: [
        'TA到底\n愛不愛我？',
        '感情是否存在\n挑戰和機遇？',
        '未來的關係\n走向如何？',
        '子女運\n如何？',
        '我們緣分\n有多深？',
        '我們會擁有\n幸福婚姻嗎?'
      ],
      homo_puzzle: [
        '我們羈絆有多深？',
        '我們會一直相互陪伴嗎？',
        '未來的關係走向如何？',
        'TA會出軌嗎？',
        'TA到底愛不愛我？',
        '性生活默契如何？'
      ],
      list: [
        '瞭解自己和對方的性格、愛情觀，同時清晰指出雙方的合拍度、互補性，更好地幫助你認識自己，瞭解對方，增進彼此的理解和信任。',
        '無論你是<span>單身</span>還是<span>戀愛中</span>無論你是想<span>找到真愛</span>還是想<span>穩固現有感情</span>愛情合盤報告都可以幫助你，讓你更清楚自己的心理需求和感情傾向，讓你更好地把握情感發展趨勢，更自信地追求自己的幸福。'
      ]
    },
    suitable: {
      title: '愛情合盤報告適用群體：',
      desc: '愛情合盤報告可以預測3種類型的關係走向',
      ambiguous:
        '與<span>暗戀對象</span>合盤\n看雙方<span>情感發展潜力</span>\n决定要不要追求',
      hetero:
        '與<span>熱戀的TA</span>合盤\n看<span>未來婚姻運勢</span>考慮要不要結婚',
      homo: '與<span>熱戀的TA</span>合盤\n看<span>未來婚姻運勢</span>\n評估雙方感情挑戰',
      marriage:
        '與<span>步入婚姻的TA合盤</span>合盘\n看<span>婚後情感生育運勢</span>\n評估雙方財運趨勢',
      marriage_homo:
        '與<span>相伴多年的TA</span>合盤\n看<span>未來生活狀態</span>\n評估雙方財運、桃花'
    },
    report: {
      tabs: ['熱戀關係', '曖昧關係', '婚後關係'],
      title: '對不同的關係提供不同的分析預測：',
      desc: '三大維度{num}個問題全方位解答',
      options: ['雙方情感', '事業財運', '未來發展'],
      options_marriage: ['双方情感', '家庭關係', '婚姻發展'],
      options_ambiguous: ['雙方情感', '戀愛成長', '脫單指南']
    },
    emotion: {
      title: '雙方情感',
      list: [
        '雙方的性格匹配度',
        '雙方的戀愛觀契合度',
        '伴侶在各自生命中的重要性',
        '雙方性生活匹配度'
      ],
      list_ambiguous: [
        '雙方的性格匹配度',
        '雙方的戀愛觀契合度',
        '對方的理想戀人類型'
      ],
      list_marriage: [
        '雙方的性格匹配度',
        '雙方婚姻觀契合度',
        '對方的理想伴侶類型', //對方的理想伴侶類型
        '伴侶在各自生命中的重要性', // 伴侶在各自生命中的重要性 배우자의 중요성,
        '雙方性生活匹配度' // 雙方性生活匹配度 두 사람의 속궁합
      ]
    },
    money: {
      title: '事業財運',
      list: ['雙方的事業觀', '雙方未來財運宮合'],
      list_marriage: [
        '雙方能給彼此帶來什麼',
        '雙方未來財運宮合',
        '雙方的子女運宮合'
      ],
      list_ambiguous: [
        '如何获得對方的好感',
        '如何加深我們的關係',
        '如果我們在一起',
        '我們之中誰更花心',
        '雙方性生活匹配度',
        '在一起可能會出現的挑戰'
      ]
    },
    future: {
      title: '未來發展',
      list: [
        '雙方的子女運宮合',
        '未來一年雙方的感情運勢',
        '情感中潜在的挑戰',
        '雙方情感中出現第三者的幾率',
        '雙方步入婚姻的機會',
        '提升戀愛關係建議'
      ],
      list_marriage: [
        '婚姻中潜在的挑戰',
        '婚姻中出現第三者的幾率',
        '未來一年的婚姻運勢',
        '提升婚姻質量建議'
      ],
      list_ambiguous: ['未來三個月脫單可能性', '旺桃花建議']
    },
    example: {
      title: '愛情合盤(type)報告示例：',
      according: '愛情合盤報告依據：',
      accordings:
        '愛情合盤報告依據合盤雙方的出生日期和時間，先分析各自的命理特徵、性格運勢，再結合兩人的喜忌關係對當前的情感狀態、未來關係走向進行預測。'
    },
    feedback: {
      title: '愛情合盤報告使用感受：',
      feed_list: [
        {
          homo_name: '**秀   2023-9-01',
          hetero_name: '**秀   2023-9-01',
          hetero:
            '在挽回情感的邊緣掙扎，但終究不是對的人，合盤報告讓我再一次意識到這一點，是真的不合適，不强求了',
          homo: '在挽回情感的邊緣掙扎，但終究不是對的人，合盤報告讓我再一次意識到這一點，是真的不合適，不强求了'
        },
        {
          hetero_name: '**金   2023-8-18',
          homo_name: '**金   2023-8-18',
          hetero:
            '單身想主動追求來的，一看合盤關係發展空間很大，果斷去追，現在已經在看日子準備結婚了嘿嘿',
          homo: '單身想主動追求來的，一看合盤關係發展空間很大，果斷去追，現在已經在看日子準備結婚了嘿嘿'
        },
        {
          hetero_name: '**名   2023-7-29',
          homo_name: '**名   2023-7-29',
          hetero: '婚後確實工作繁忙异地比較多，合盤報告裡面講的真的神准！',
          homo: '婚後確實工作繁忙异地比較多，合盤報告裡面講的真的神准！'
        }
      ]
    },
    illustrate: {
      title: '愛情合盤報告使用說明：',
      homo: [
        '1.需要认真填写合盘双方的完整信息，包括姓名、性别、生日；',
        '2.同性爱情合盘报告的双方性别必须为同性；',
        '3.合盤報告支付後將在30秒內生成結果，生成後可以保存報告，保存後可以通過以下管道查看：下載HiSeer APP，通過保存時驗證的郵箱/手機號進行登入後，在我的-【報告與占卜】中查看； 在本網頁的【歷史訂單】中，蒐索郵箱/手機號/訂單號進行查看。'
      ],
      hetero: [
        '1.需要认真填写合盘双方的完整信息，包括姓名、性别、生日；',
        '2.男女愛情合盤的雙方性別必須為异性；',
        '3.合盤報告支付後將在30秒內生成結果，生成後可以保存報告，保存後可以通過以下管道查看：下載HiSeer APP，通過保存時驗證的郵箱/手機號進行登入後，在我的-【報告與占卜】中查看； 在本網頁的【歷史訂單】中，蒐索郵箱/手機號/訂單號進行查看。'
      ]
    },
    loading: {
      title: '愛情合盤分析中',
      list: [
        '性格契合度分析',
        '人生大事順位對比',
        '財運起伏趨勢預測',
        '感情危機應對建議'
      ],
      list_marriage: [
        '婚姻契合度分析',
        '人生大事順位對比',
        '財運起伏趨勢預測',
        '未來婚姻關係分析'
      ],
      list_ambiguous: [
        '性格契合度分析',
        '未來雙方關係預測',
        '雙方花心程度分析',
        '桃花催旺脫單建議'
      ]
    },
    love: {
      emotion: '雙方情感',
      match_emotion: '雙方的性格匹配度',
      similarity: '性格相似度：',
      complementary: '性格互補性：',
      outlook_match: '雙方的戀愛觀契合度',
      love_match: '戀愛契合度',
      impression: '如何获得對方的好感',
      relative: '如何加深我們的關係',
      enhance_advise: '關係提升建議',
      together: '如果我們在一起',
      playful: '我們之中誰更花心',
      possible: '未來三個月脫單可能性',
      luck: '旺桃花建議',
      challenge: '在一起可能會出現的挑戰',
      marriage_match: '雙方婚姻觀契合度',
      ideal_partner: '對方的理想伴侶類型',
      marriage_promote: '雙方能給彼此帶來什麼',
      fortunate_match: '雙方未來財運宮合',
      child_match: '雙方的子女運宮合',
      marriage_challenge: '婚姻中潜在的挑戰',
      marriage_cheat: '雙方情感中出現第三者的幾率',
      marriage_enhance: '提升婚姻質量建議',
      mate_importance: '伴侶在各自生命中的重要性',
      attitude: '雙方對金錢的態度',
      now_sort: '現階段的排序',
      life_sort: '一生的排序',
      sort_list: ['理想', '社交/朋友', '孩子', '事業/財富', '另一半'],
      sex_match: '雙方性生活宫合',
      sex_perform: '雙方的性生活表現',
      sex_desire: '雙方的性欲强弱',
      sex_rate: '性生活匹配度'
    },
    similaritys: ['你們的性格有幾分相似？', '你們的性格有幾分互補？'],
    importances: ['對你而言什麼最重要？', ' 對TA來說另一半排在第幾位？'],
    sexs: ['你們的性生活合拍嗎？', '你們的性生活表現如何？'],
    wealthys: ['你們主要的財富來源於何處？', '你們的未來財運趨勢如何？'],
    childs: ['你們一生可以有幾個子女？', '你們與子女的關係如何？'],
    chances: ['你們的情感狀態如何？', '未來三年結婚的概率如何？'],
    love_match: ['雙方的戀愛理念是什麼？', '會通過何種方式表達愛？'],
    impression: ['對方的喜好如何？', '怎麼表現才能引起TA的注意？'],
    playful: ['雙方花心程度如何？', '內心對戀愛持何種態度？'],
    love_relative: ['未來一年情感發展情況如何？', '怎樣為我們的戀愛加滿火力？'],
    possible: ['未來三個月脫單的具體幾率？', '除了對方是否存在其他桃花？'],
    love_luck: ['你的桃花運如何？', '哪些管道可以幫你快速脫單？'],
    marriage_match: ['雙方對待婚姻態度如何？', '婚姻對TA而言重要嗎？'],
    marriage_promote: ['在生活事業方面，', '彼此能相互促進、共同發展嗎？'],
    fortunate_match: ['你們主要的財富來源於何處？', '你們的未來財運趨勢如何？'],
    marriage_challenge: [
      '彼此能相互促進、共同發展嗎？',
      '哪些因素會影響婚姻穩定？'
    ],
    marriage_advise: ['如何提升婚姻幸福指数？', '哪些事情可以促进双方情感？'],
    fortunate: '事業財運',
    both_fort: '雙方的事業觀',
    wealthy: '雙方未來財運宮合',
    both_weal: '雙方的財富來源',
    future_develop: '未來發展',
    weal_compare: '未來財運趨勢對比：',
    weal_fortunate: '財富運勢',
    emo_fortunate: '感情運勢',
    score: '分',
    score_unit: '分數',
    child: {
      match: '雙方的子女運宮合',
      fate: '與子女的緣分',
      importance: '子女對雙方的重要性',
      effect: '雙方對子女的影響'
    },
    challenge: '情感中潜在的挑戰',
    chance: '雙方步入婚姻的機會',
    third: '雙方情感中出現第三者的幾率',
    marriage: '近三年結婚幾率：',
    emotion_fort: '未來一年雙方的感情運勢',
    emotion_chart: '情感趨勢圖',
    marriage_fort: '婚姻運勢',
    marriage_chart: '婚姻運勢圖',
    advice: '提升戀愛關係建議',
    advice_hiseer: '合盤報告準確率如何…',
    recommend: '推薦情感占卜',
    more: '更多情感占卜'
  },
  // 年运报告
  annual: {
    title: '年度運勢報告',
    year: '預測年份',
    place_year: '請選擇您要預測的年份',
    predict: '立即測算',
    activity: '2024元宵節前限時送龍年開運符\n助您好運一整年！',
    example: '年度運勢報告示例',
    paid: '<span>num</span>人下單了年度運勢 定制報告\n<span>98.9%</span>用戶覺得很准',
    alter: '更改',
    couplet1: '大師為你出謀劃策',
    couplet2: '在玄妙的命運中',
    couplet3: '不知何時進退',
    schedule: {
      steps: [
        '收貨地址填寫已完成',
        { text: '開運符開光儀式', success: '開運符開光儀式已完成' },
        { text: '開運符發貨', success: '開運符發貨已完成' }
      ],
      upload: '儀式完成後將為您上傳視頻',
      play: '點擊播放開光視頻',
      express: '物流單號：',
      contact_us: '如有疑問，請通過以下管道聯繫我們：',
      tips: '使用郵箱賬號\nemail登錄HiSeer App\n可查看年度運勢報告結果、開運符進度',
      email_tip: '登錄HiSeer App\n可查看年度運勢報告結果、開運符進度'
    },
    master: {
      title: '年度運勢 定制報告',
      text: '專業命理師預測+真人撰寫為你提前預報年度運程',
      overview: '一、年度運勢綜合概況',
      overview_list:
        '這一年生活的重心會在哪些方面？\n會獲得哪些方面的發展？\n會遭遇哪些方面的挫折？',
      analysis: '二、6大維度運勢詳細分析',
      analysis_list: [
        {
          title: '愛情運勢',
          content: '什麼時候桃花運最旺？\n是否有情感危機？'
        },
        { title: '事業運勢', content: '什麼時候事業運好？\n能否升職加薪？' },
        { title: '財富運勢', content: '什麼時候財運好？\n財富來源何處？' },
        { title: '社交運勢', content: '社交運勢如何？\n適合交往哪些朋友？' },
        {
          title: '學業運勢',
          content: '學業成績如何？\n能否通過學習獲得提升？'
        },
        { title: '健康運勢', content: '年度健康情况如何？\n需要注意哪些事項？' }
      ],
      guide: '三、助運指南',
      guide_bold: '涵蓋利好方向、喜用顏色、幸運數字在內等多方面的好運指南',
      guide_list:
        '哪個方向有利發展？\n哪些顏色是我的年度喜用色？\n能給我帶來幸運的生活方式有哪些？',
      service: '真誠的服務，不負您的新年期待',
      service_content: `我們書寫的每一份年運報告，寫下的每一句分析綜述，都來自專業命理師的用心推演。

      我們每天承接上百個年度運勢報告服務，逐一推演耗神費心，但出於我們對用戶負責的態度，為了對得起您對於新年的期待，我們必須如此，也請您理解從命理分析到形成書面報告需要必要的時間。

      下單後我們將按順序安排年運分析，一般會在24小時內完成報告，請耐心等待。`
    },
    charm: {
      title: '開運符助運',
      desc: '現在立即下單大師年運詳批，除了獲得大師真人年運分析報告，還可獲得經由道觀製作、開光的【龍年開運符】，我們幫您把好運送到家',
      list: [
        {
          name: '財運符',
          content:
            '財運符可以幫助你廣招財源、消解災難。 如果你的財路正旺，那麼它可以添一把火，如果你的事業不順，那麼它的作用就是雪中送炭。 召集五路財神暗中為你廣開財路，為你新增機遇，以獲得偏財運與偏財，獲得意外之財。'
        },
        {
          name: '事業符',
          content:
            '事業符可以為你帶來好運和機遇，激發事業潜能，獲得貴人相助，讓你在事業和職場上更容易獲得成功。 它可以吸引積極的能量，讓你在工作中更加順利，讓你的職業生涯更加順風順水。'
        },
        {
          name: '桃花符',
          content:
            '能够催旺桃花運，進而獲得异性的愛慕跟追求。 對於單身的男女來講，能够幫助早日遇到心儀對象，招來好姻緣； 對於已經戀愛的人而言，也能够改善感情不順的情况，促進關係升溫，早日走進婚姻殿堂，讓感情幸福美滿。'
        },
        {
          name: '學業符',
          content:
            '學業文昌符的主要作用是祈求學業順利、考試順利及升學升職等。 具有提升學業運勢和智慧的作用。 使用文昌符可以輔助學習，增强學習動力和積極心態，提高專注力和記憶力。'
        },
        {
          name: '平安符',
          content:
            '平安符可以護佑人們身體和心靈健康、保佑出入平安、擋住小人。 適合各類人士，例如經常出行的人們，平安符可以起到一種保平安的作用。 同時為自己或家人求得平安符新增身體的能量或改善身體狀況。'
        },
        {
          name: '人緣符',
          content:
            '廣結人緣符能够幫助你擴展社交圈，使你更容易建立新的人際關係。 幫助你打開人們的心扉，使他們對你產生好感和信任，讓你在社交場合更加受歡迎，與他人建立更深入的聯系，享受更多的支持和幫助。'
        }
      ],
      tip: '六種實體開運符您可以選擇領取其中一種',
      writing: '所有開運符皆為道觀大師親筆繪製、開光認證的符',
      lighting: '我們將根據您提供的個人資訊為每位用戶做獨家定制的開光儀式',
      video: '點擊查看開運符繪製視頻',
      light: '點擊查看開光儀式視頻',
      deliver: '開運符製作完畢我們將根據您提供的地址為您郵寄到家',
      color: '顏色隨機',
      gift: '贈',
      gift_desc:
        '您可以將開運符放在隨身攜帶的包裏、車上、客廳，它將為您帶來無限好運',
      gift_title: '【開運符】限時贈送：',
      gift_content:
        '即日起至甲辰年正月十五（2024年2月24日）期間，下單年運報告可領開運符（六選一），過期不再贈送，機不可失，不要錯過喔！'
    },
    comment: {
      title: '年度運勢報告使用感受',
      list: [
        {
          name: '**靜 2023.10.3',
          content:
            '大師寫得特別詳細，各方面都覆蓋到了，我最想知道的情感運勢也給到了12個月的趨勢圖，56月桃花最好，2024加油脫單！！！'
        },
        {
          name: '**語 2023.9.16',
          content: '感謝大師的建議，明年適合換工作，太好了！'
        },
        // {
        //   name: '**傾 2023.11.1',
        //   content:
        //     '開運符收到了！ 我放在入戶門附近，每天進出都能看到，很安心，謝謝~'
        // }
        {
          name: '**金 2024.2.16',
          content:
            '新年收到大師的年運報告，對新的一年有了新希望，有數了，謝謝。'
        }
      ]
    },
    illustrate: {
      title: '報告使用說明',
      list: [
        '1.年度運勢報告需要填寫姓名、性別、生日、預測年份；',
        '2.為保證預測準確性，年度運勢報告由專業運勢預測大師團隊線上分析、預測撰寫，一般會在提交資訊後24小時內完成報告，請耐心等待；',
        // '3.為了順利寄出開運符，我們需要收集您的收貨資訊，需要輸入您的真實姓名、具體住址、手機號、郵箱等內容，請認真填寫；',
        '3.已完成的年度運勢報告您可以通過以下管道查看：'
      ],
      tip_list: [
        '年運報告結果將發送至您填寫的郵箱，您可以在郵箱中查看；',
        '下載HiSeer APP，通過您填寫的郵箱進行登入後，在【年度運勢報告】-<歷史記錄>中查看；',
        '在本網頁的【歷史訂單】中，蒐索郵箱或訂單號進行查看。'
      ],
      contact: '如有疑問，請通過以下管道聯繫我們：'
    },
    payment: {
      title: '支付確認',
      prod: 'x年度運勢 定制報告',
      name: '預測對象',
      pay: '待支付',
      type: '解鎖方式：',
      tip: '大師會在24小時內將年運報告發至您的郵箱',
      email: '電子郵箱',
      button: '驗證郵箱',
      e_title: '郵箱驗證',
      e_sent: '驗證郵件已發送至',
      e_toemail: '請前往收件箱進行驗證',
      e_tip: '（如未收到請查看垃圾郵件）',
      e_warning: '請先完成郵箱驗證',
      e_confirm: '驗證完成',
      success: '驗證成功',
      verify_again: '重新驗證',
      try_again: '未檢測到郵箱驗證操作，請重試'
    },
    info: {
      submit: '您的年運定制報告訂單已提交給命理師',
      year: '預測年份',
      submit_time: '提交時間：',
      tips_1: '',
      tips: '命理師將在&之前將年運報告結果發送至郵箱email，請耐心等待……',
      tips_out: '命理師正在撰寫報告，請耐心等待…',
      tips_gray: `已完成的年運報告您可以通過以下管道查看：
      年運定制報告結果將發送至您填寫的郵箱，您可以在郵箱中查看；在本網頁的【歷史訂單】中，蒐索郵箱或訂單號進行查看。`,
      e_tips_1: '使用郵箱賬號',
      e_tips_2: '登錄HiSeer App',
      e_tips_3: '可查看年度運勢報告',
      confirm: '開運符領取確認',
      confirm_text:
        '開運符為年度運勢報告的獨家定制贈品，請點擊領取並填寫收貨資訊',
      cancel: '放弃領符',
      fillin: '領取開運符',
      cancel_warning:
        '開運符為年度運勢報告的獨家定制贈品，現在選擇放弃後，將無法再次獲取，您確認放弃領取開運符的權益嗎？',
      giveup: '你已放弃領取開運符',
      deliver: '開運符郵寄費用由我們承擔。',
      deliver_tip:
        '為了順利寄出開運符，請認真填寫以下資訊，保證資訊的真實、準確，如果由於您的填寫失誤造成無法正常簽收開運符，您將自行承擔該損失。',
      deliver_tip_1:
        '發貨後我們將通過電子郵箱告知您運單號，您也可以在APP中查看開運符進度',
      select: '請選擇您想要的開運符種類',
      options: ['財運符', '事業符', '桃花符', '學業符', '平安符', '人缘符'],
      deliver_form: [
        { type: 'name', label: '收貨人姓名', placeholder: '請輸入收貨人姓名' },
        { type: 'phone', label: '收貨人手機號', placeholder: '请输入手机号' },
        { type: 'address', label: '收貨地址', placeholder: '請輸入收貨地址' },
        { type: 'email', label: '電子郵箱', placeholder: '請輸入正確的郵箱' },
        { type: 'postCode', label: '郵遞區號', placeholder: '請輸入郵遞區號' }
      ],
      exceed: '最多輸入num字元',
      err: {
        type: '請選擇開運符種類',
        name: '請輸入收貨人姓名',
        phone: '請輸入正確的手機號',
        address: '請輸入收貨地址',
        email: '請輸入正確的郵箱',
        postCode: '請輸入正確的郵遞區號'
      },
      schedule: '開運符進度>',
      outdated: '活動已過期',
      submitted: '收貨資訊已填寫'
    },
    detail: {
      overview: '一、年度綜合運勢概況',
      over_subtitle: '年度綜合運勢概況',
      radar: '年度運勢雷達圖',
      radar_tips: '數值越高代表該方面的運勢就越好，僅供參考',
      list: [
        '愛情運勢',
        '事業運勢',
        '財富運勢',
        '學業運勢',
        '社交運勢',
        '健康運勢'
      ],
      tu: '圖',
      analy: '二、年度運勢詳細分析',
      month: '月份',
      score: '得分',
      start: '2月（立春）',
      end: '1月（小寒）',
      guide: '三、助運指南',
      guide_list: [
        '助運發展方向、風水坐向、環境：',
        '助運顏色、數位：',
        '助運飲食：',
        '助運生活方式：',
        '助運飾品：',
        '助運生肖：'
      ],
      guide_tips:
        '（在確保佩戴飾物、擺件不與本人及家人生肖刑、沖、害的基礎上。）',
      master: '大師簽名：',
      share: '分享報告>',
      get: '點擊獲取我的年運 定制報告',
      share_txt: '快來看看HiSeer大師為我撰寫的2024年運報告>> HiSeer app - ',
      download: '下載HiSeer App\n獲取年度運勢報告'
    }
  },
  // 问卦咨询
  hexagram: {
    name: '占卜者姓名',
    gender: '占卜者性别',
    question: '想要占卜的問題',
    question_place: '請輸入你想諮詢的具體問題',
    update: '換一批',
    q_err: '最多輸入50字元',
    start: '起卦',
    manual: '裝挂',
    time: '日期',
    time_err: '請輸入起卦時間',
    question_1: '卦題',
    title_1: '起卦必讀',
    tips: '如何起卦才能得到較為準確的結果',
    cover_list: ['問情感', '問事業', '問財運', '問學業', '問健康'],
    cover_text: '一事一占 準確預測\n大師解讀 趨吉避凶',
    more_question: '猜你想問',
    select: '請選擇',
    six_err: '六爻選項不能為空',
    list: [
      {
        q: '六爻占卜適用於詢問具體事情，越具體越好。',
        r: '今天面試能否成功？',
        f: '我應該從事什麼職業？'
      },
      {
        q: '一事一占，每次占卜只問一件具體事情',
        r: '他喜歡我嗎？',
        f: '他喜歡我嗎？ 我們會在一起嗎？ 在一起多久？'
      },
      {
        q: '如果問題與時間或數位有關，一定要加上時間或數位前提。',
        r: '我最近一個月能找到工作嗎？',
        f: '我能找到工作嗎？'
      },
      {
        q: '占卜時集中心神，對問題描述清楚，以便於自己清晰問題or讓諮詢師更明確的瞭解您的情况。（問題越具體，解答越準確）'
      }
    ],
    example: [
      {
        title: '情感問卦示例',
        label_q: '问情感',
        question: '我應該跟他結婚生子嗎？',
        label_r: '回復示例',
        reply:
          '您好，此卦為風火家人之水雷屯，世爻空說明您對於這個情感的未來心中無底。 不過卦中子孫動來生世，說明你們結婚未來還是不錯的，對生孩子還是比較合適的。 但是值得注意的一點是，您的另一半在未來生活中相比孩子可能不是最重要的。 情感生活過得比較平穩不是非常濃烈的那種。 不過總體來說兩個人結婚生子還是屬於比較合適的。 另外提示：卦中提示近期需要多注意父母健康和安全。 以上供參考。'
      },
      {
        title: '事業問卦示例',
        label_q: '问事業',
        question: '今年能否晋昇？',
        label_r: '回復示例',
        reply:
          '您好，根據您的卦象看，今年晋昇的機會比較小，同時競爭比較激烈，你身邊很多人都在尋求機會，您現在處於比較擔憂，有壓力的狀態下。 但是不用擔心，您與官同爻，也就是說領導是有關注你並看好你的，晋昇是早晚的事情，但不是這一次。 升職建議，這段時間多增强自己的專業技能和知識，另外多和領導交流搭建良好的關係。 以上是根據卦象給出的結論，請供參考！'
      },
      {
        title: '財運問卦示例',
        label_q: '问財運',
        question: '这次合作能否赚到钱？',
        label_r: '回復示例',
        reply:
          '您好，根據您提供的卦象看，兄弟持世財不上卦，這個合作可能賺錢的幾率比較小。 您現時心中也是比較沒底，對方想推進和合作的意向更高。 這個卦象顯示破財的迹象更高，有可能您投入的資金難以回本。 建議您可以從多維度去評估和調研清楚再進一步考慮投資意向，以上預測供參考。'
      },
      {
        title: '學業問卦示例',
        label_q: '问學業',
        question: '我能考上喜欢的学校吗？',
        label_r: '回復示例',
        reply:
          '您好，此卦為水地比之澤水困，官爻持世，說明考試有難度，您現時也是處於壓力較大比較焦慮的狀態。 另外卦中父母動化兄弟，說明此次考試的競爭性比較大，報考的人比較多，學校會更傾向於錄用其它學生。 囙此總體來說直接考進這個學校的難度比較大，除非有其它資源可以加持。 建議：如果非常想進這個學校的話，還需要在成績上下功夫。 如果還有其它目標學校可以再占。 以上供參考。'
      },
      {
        title: '健康問卦示例',
        label_q: '问健康',
        question: '孩子最近经常发烧，何时恢复健康？',
        label_r: '回復示例',
        reply:
          '您好，根據您提供的卦象判斷，可以看出孩子現時的病情會持續一段時間，可能會有藥不對症的情况出現。 但是無需過於擔心，孩子的情况不會引發其它較嚴重的症狀，最晚不超過午月（陽曆6月份），孩子的病情會得到很好的緩解以及自愈。 建議：調整好孩子的飲食、生活作息以及孩子的心情狀態，提高身體的自我恢復機能。'
      }
    ],
    feedback: {
      title: '問卦感受',
      list: [
        {
          time: '**顏 2023.9.14',
          question: '占卜問題：現在跳槽合適嗎？',
          comment: '評價：謝謝！ 有很大的幫助指引'
        },
        {
          time: '**惟 2023.9.6',
          question: '占卜問題：我應該去挽回這段感情嗎？',
          comment: '評價：謝謝指點，收穫很多，希望自己可以更好配得上她'
        },
        {
          time: '**伶 2023.9.6',
          question: '占卜問題：我今年能否順利考上大學？',
          comment:
            '評價：“難度比較大，除非有其它資源可以加持”，確實競爭激烈，但是也真心希望自己可以考上，繼續努力~'
        }
      ]
    },
    illustrate: {
      title: '問卦諮詢使用說明',
      content: `1.認真填寫問卦人姓名、性別、想要測算的問題，一事一占，支付後將提交問卦資訊給諮詢師；
      2.為保證預測準確性，所有問卦諮詢由HiSeer團隊從業十餘年的資深命理諮詢師線上分析、預測撰寫，一般會在提交資訊後24小時內完成解卦，最終以文字（150字左右）或語音消息的形式回復您，請耐心等待；
      3.如果用戶未按照起卦規則，同時提問多個問題或提問問題描述不清晰，諮詢師會給您發送修改郵件，請您及時進行修改並重新提交；
      4.已完成的問卦諮詢記錄您可以通過以下管道查看：
      問卦結果將發送至您填寫的郵箱，您可以在郵箱中查看；
      下載HiSeer APP，通過您填寫的郵箱進行登入後，在【問卦諮詢】中查看；
      在本網頁的【歷史訂單】中，蒐索郵箱或訂單號進行查看。`
    },
    yaoing: '正在爻卦',
    sixyao: ['一爻', '二爻', '三爻', '四爻', '五爻', '六爻'],
    positive: '正',
    opposite: '反',
    consult: '找大師諮詢解卦',
    rule: '諮詢規則',
    rules: `1.認真填寫問卦人姓名、性別、想要測算的問題，一事一占，支付後將提交問卦資訊給諮詢師；

    2.為保證預測準確性，所有問卦諮詢由HiSeer團隊從業十餘年的資深命理諮詢師線上分析、預測撰寫，一般會在提交資訊後24小時內完成解卦，最終以文字（150字左右）或語音消息的形式回復您，請耐心等待；

    3.如果用戶未按照起卦規則，同時提問多個問題或提問問題描述不清晰，諮詢師會給您發送修改郵件，請您及時進行修改並重新提交；

    `,
    check_tips: {
      title: '4.已完成的問卦諮詢記錄您可以通過以下管道查看：',
      list: [
        '問卦結果將發送至您填寫的郵箱，您可以在郵箱中查看；',
        '下載HiSeer APP，通過您填寫的郵箱進行登入後，在【問卦諮詢】中查看；',
        '在本網頁的【歷史訂單】中，蒐索郵箱或訂單號進行查看。'
      ]
    },
    info: {
      q: '占卜問題：',
      name: '卦名：',
      detail: '占卜卦象：',
      submitted:
        '問卦諮詢已提交命理師將在time之前將您的問卦解讀結果發送至email，請注意查收',
      waiting: '諮詢師處理中，請耐心等待',
      add: [
        '為了更好地幫助您解卦，請描述關於所占卜問題的背景資訊，描述越具體，解讀越準確。',
        '占卜師需要通過卦象結合實際情況來為您關心的問題做更精准的吉凶趨勢判斷哦。'
      ],
      add_title: '補充說明：',
      placeholder:
        '針對提問背景進行補充說明，提問的背景描述、前因後果越詳細，解讀越準確',
      err: '請輸入補充說明',
      cancel: '暫不補充',
      confirm: '提交補充資訊',
      tips: '使用郵箱帳號\n email 登入HiSeer App\n可同步查看問卦解讀進度'
    },
    payment: '大師解卦完成將把結果發送至該郵箱',
    detail: '問卦諮詢詳情',
    reply: '諮詢師回復',
    reply_t: '回復時間：',
    replenish: '請補充資訊',
    modify: '修改資訊',
    playing: '正在播放語音…'
  },
  // 职能报告
  enterprise: {
    assis: '企業助手',
    title: '企業智慧人才測評',
    desc: '全方位智慧評估 助您慧眼識良才',
    start: '開始測評',
    recruit:
      '招聘中，企業過多關注求職者的表面行為，招到了學歷達標、專業對口的員工，但他們在實際工作中卻總是表現平平。\n因為大多數企業都忽略了這樣一個事實——真正决定員工工作表現的因素，是冰山下的隱性特質。',
    iceburg: {
      top: '可以觀察的顯性行為',
      t_num: '冰山上30%',
      b_num: '冰山下70%',
      bottom: '難以培養的隱性特質',
      t_keys: ['知識', '技能'],
      b_keys: ['能力', '性格', '動機', '天賦'],
      under:
        '冰山下如此重要，隱性特質很難被培養，企業或崗位又需要具備這些貭素的員工，怎麼辦？為了幫助突破企業招聘困境，我們給出了自己的答案——',
      review: '企業智慧人才測評',
      review_desc:
        '測評報告結合最專業的人格算灋，高效抓取候選人的內在特質，準確檢驗候選人真實水准，通過多方面分析，以環環相扣的管道，探究冰山下的真相，助您慧眼識良才。'
    },
    content: {
      title: '企業智慧人才測評報告主要內容',
      assess: {
        title: '候選人當前能力評估',
        position: '精准聚焦候選人最突出的特質',
        five: '考量候選人在5個維度各方面的表現',
        vantages: '評估候選人的優劣勢',
        radar: ['人際互動', '思維模式', '目標執行', '抱負能量', '情緒成熟度'],
        subtitle_1: '人才定位',
        subtitle_2: '綜合評估',
        subtitle_3: '職業特質',
        subtitle_4: '職業能力分佈'
      },
      analy: {
        title: '候選人未來發展預測',
        subtitle1: '未來5年職業前景評估',
        list1: ['是巔峰還是低谷？', '是平穩還是波折？'],
        subtitle2: '未來5年職業發展趨勢圖',
        list2: ['近期能否擔任覈心崗位？', '穩定程度如何？'],
        ability: '分析候選人的工作能力'
      },
      guide: {
        title: '企業用人指南',
        subtitle1: '如何管理善用此職員',
        list1: [
          '安排何種崗位角色可以發揮其特長',
          '什麼樣的工作方式可以激發其工作動力',
          '提供什麼資源可以幫助其成長'
        ],
        text1: '幫助企業實現真正的因“才”施用',
        subtitle2: '用人風險及注意事項',
        list2: [
          '雇用候選人需要注意哪些事項',
          '候選人在工作中可能會遇到哪些問題',
          '如何管理可以避免用人風險'
        ],
        text2: '協助企業避免用人風險'
      },
      example: '企業智慧人才測評報告示例',
      feeling: {
        title: '企業智慧人才測評使用感受',
        list: [
          {
            name: '**東xxxxx公司人力資源部門經理',
            text: '用這個智慧人才測評一段時間，最大的感受是精准！ 對於我們招聘者而言，找到合適的人比找到厲害的人更重要，這個系統可以幫助我們快速掌握求職者的特點，判斷是否合適，提高了我們招人的效率。'
          },
          {
            name: '**敏 xxxxx公司 CEO',
            text: '企業用人指南這部分給我們很多實用的建議，幫我們我完善了企業人才管理方面的製度，而且員工反應也很好啊。'
          },
          {
            name: '**飛xxxxx公司VP',
            text: '這個人才測評報告，我主要用來參考覈心員工的近期發展和離職意向，這些員工都是經過長時間的磨合和培養的，瞭解他們的發展狀態，對於員工管理和規劃很有幫助。'
          }
        ]
      },
      illustrate: {
        title: '企業智慧人才測評使用說明',
        content:
          '1.需要認真填寫候選人的姓名、性別、生日等資訊；\n2.支付後智慧人才測評報告將在30秒內生成結果，生成後可以永久查看，已完成的報告您可以通過以下管道查看：記錄訂單號，或者通過驗證郵箱/手機號的管道進行保存，後續可以在本網頁的【歷史訂單】中，蒐索郵箱/手機號/訂單號進行查看。'
      },
      loading: {
        title: '正在分析......',
        list: ['能力評估', '未來發展', '用人指南']
      }
    },
    loading: {
      title: '正在分析......',
      list: ['能力評估', '未來發展', '用人指南']
    },
    object: '測評對象',
    score: '人才評分',
    overall: '綜合評分',
    warning:
      '當前測評對象的生日資訊不完整，囙此分析結果存在不準確的因素，誤差在20%左右',
    line_warning:
      '該段內容涉及未來5年職業發展的整體展望，需要注意這一描述並不代表每年發展的好壞。',
    assess: '候選人能力評估',
    analy: '候選人發展預測',
    guide: '企業善用管理指南',
    assess_list: ['思維模式', '人際互動', '情緒成熟度', '目標執行', '抱負能量'],
    year: '年份',
    shot: '保存報告為圖片',
    lunar: '陰曆',
    solar: '陽曆',
    save_tips: '請仔細記錄您填寫的帳號，\n後續您可通過此帳號蒐索訂單',
    prompt:
      '不保存將會新增報告遺失的風險，保存後您可以在郵箱內查看報告，是否保存報告？',
    success: '發送成功！報告已發送至郵箱請及時查收~'
  },
  career: {
    title: '職業發展測評報告',
    desc: '量身定制職業規劃工作發展快人一步',
    items: ['工作能力評估', '職業發展預測', '職業生涯建議'],
    questions: [
      '你還在工作中的困境苦苦掙扎嗎？',
      '你是否缺乏動力或覺得自己沒有發揮潜力？',
      '還是晋昇困境讓你夜不能寐？'
    ],
    bubbles: [
      '我適合做什麼樣的工作？',
      '我的核心競爭力是什麼？',
      '該不該跳槽？',
      '明年有晋昇機會嗎？',
      '哪個工作環境更適合我？'
    ],
    sayno: '<span>職業發展測評</span> ，助你前程無憂，對職場困境說NO！',
    construct: [
      { title: '工作能力評估', content: '快速定位\n你的核心競爭力' },
      { title: '職業發展預測', content: '精准預測\n你的職業運勢' },
      { title: '職業生涯建議', content: '量身定制\n你的職業建議' }
    ],
    result: '職業生涯 提前規劃\n升職加薪 快人一步',
    content: {
      title: '職業發展測評報告主要內容',
      position: '職業定位',
      positions: [
        '自主獨立型',
        '引領開創型',
        '責任承擔型',
        '靈活決策型',
        '行動導向型',
        '目標協作型'
      ],
      special: '精准聚焦個人職業特質',
      assess: '評估5個維度各方面的表現',
      character: '快速抓取個人優劣勢',
      character_list: [
        '批判性',
        '執行力',
        '適應性',
        '開放性',
        '無視規則',
        '粗心大意',
        '保守'
      ],
      ability: '一體兩面分析工作能力',
      forecast: '未來5年職業前景評估',
      forecasts: ['是巔峰還是低谷？', '是平穩還是波折？'],
      trend: '未來5年職業發展趨勢圖',
      trends: ['升職機遇如何？', '是否存在職業危機？'],
      guide: {
        title: '職場指南',
        list: [
          '選擇何種類型的崗位可以發揮自身特長',
          '什麼樣的工作方式可以激發工作動力',
          '什麼樣的公司平臺可以幫助個人成長'
        ],
        text: '找准真正的職業天賦，輕鬆實現個人價值'
      },
      advice: {
        title: '發展建議',
        list: [
          '你該如何靈活適應職場環境',
          '如何行事可以避免犯錯',
          '哪些關鍵細節需要格外注意'
        ],
        text: '幫助你减少失誤，降低職業風險'
      },
      swiper: '職業發展測評報告示例',
      feeling: {
        title: '職業發展測評報告使用感受',
        list: [
          {
            name: '**志',
            text: '綜合評估的部分是怎麼做到這麼準確的… 准到失語，我就是典型的“思維”和“執行”跟得上，所以選擇了當碼農哈哈哈'
          },
          {
            name: '**栩',
            text: '這個報告不錯的，對我們這些馬上畢業的學生來講很實用，測完更有信心了，沖！'
          },
          {
            name: '**鹃',
            text: '好准呐！ 正如報告測到的，我本人其他能力都一般，也就人際溝通比較擅長，所以現在幹銷售'
          }
        ]
      },
      illustrate: {
        title: '職業發展測評報告使用說明',
        content:
          '1.需要认真填写候选人的姓名、性别、生日等信息；\n2.支付後職業發展測評報告將在30秒內生成結果，生成後可以永久查看，已完成的報告您可以通過以下管道查看：記錄訂單號，或者通過驗證郵箱/手機號的管道進行保存，後續可以在本網頁的【歷史訂單】中，蒐索郵箱/手機號/訂單號進行查看。'
      }
    },
    loading: {
      analysing: '正在分析......',
      list: ['工作能力評估', '職業發展預測', '職業生涯建議']
    },
    tablist: ['能力評估', '未來發展', '職業建議']
  },
  // simple-report组件完整天赋部分数据
  simple: {
    // 本名解读
    talent: '最強天賦',
    famous: '典型\n名人名家',
    values: '價值觀',
    personality: '性格分析',
    think: '思維特點',
    potential: '潜力提升',
    // 人际交往
    six: '六親關係',
    elegant: '貴人分析',
    // 职业规划
    career: '事業觀',
    advice: '從業建議',
    // 恋爱指南
    love: '愛情觀',
    best_lover: '最佳\n交往對象',
    unlock: '解鎖完整報告',
    split_keyword: {
      title: '你的天賦關鍵字',
      content: '提煉你最顯著的特徵是什麼？\n你的人格畫像是什麼樣的？'
    },
    s_talent: {
      title: '你的最强天賦',
      content: '解讀你的天賦優勢是什麼？\n你是哪個類型的潜力股？'
    },
    s_nature: {
      title: '你的性格分析',
      content: '解讀你的性格優勢是什麼？\n分析你的性格弱點是什麼？'
    },
    s_potential: {
      title: '潜力提升建議',
      content: '指導你如何克服缺點，發揮優勢\n如何發掘潜力實現理想'
    },
    s_talent_image: {
      title: '你的天賦形象'
    },
    s_celebrity: {
      title: '與你相似的名人',
      content: '與你人格相似度最高的名人是誰\n他們從事著什麼樣的職業'
    },
    s_values: {
      title: '你的價值觀',
      content: '解讀什麼對你來說最重要\n你最具發展潜力的精神特質是什麼'
    },
    s_thinking: {
      title: '你的思維特點',
      content: '解讀你最擅長的思維方式是什麼\n分析哪個方面的思維意識需要提升'
    },
    s_love_nature: {
      nature: '戀愛性格',
      title: '你的戀愛性格',
      content: '解讀你在愛情中扮演著怎樣的角色\n你會怎樣對待你的伴侶'
    },
    s_best_lover: {
      title: '最佳交往對象',
      content: '匹配最適合與你相伴一生的人是誰'
    },
    s_relatives: {
      title: '你的六親關聯性排名',
      content: '解讀你與六親的親疏關係\n對你影響最大的人是誰'
    },
    s_affect_life: {
      title: '你的貴人',
      content: '分析六親中誰對你有幫助\n什麼類型的人是你的貴人'
    },
    s_profession_nature: {
      title: '你的職業性格',
      content: '解讀你在職場中扮演什麼角色\n你會怎樣對待工作和同事'
    },
    s_profession_ability: {
      title: '你的職業能力分佈',
      content: '分析工作能力長短板\n提示需要提升的職業方面'
    },
    s_profession_type: {
      title: '適合你的職業類型',
      content: '指導就業從業方向\n匹配適合你的職業類型'
    }
  },
  request: {
    network_err: '網絡異常'
  },
  // 免费排盘
  plate: {
    eight: '八字排盤',
    name: '姓名',
    gender: '性别',
    country: '出生地',
    birth: '生日',
    submit: '起盤',
    restart: '重新起盤',
    name_placeholder: '請輸入姓名',
    birth_placeholder: '請輸入出生日期',
    history: '歷史記錄',
    no_show: '顯示\n干支',
    g: '顯示\n藏幹',
    spirit: '顯示\n十神',
    element: '顯示\n五行',
    fortunate: '大運',
    flow_year: '流年',
    flow_month: '流月',
    flow_day: '流日',
    flow_hour: '流時',
    more_sun: '下載HiSeer瞭解更多日幹知識>>',
    more_destiny: '下載HiSeer瞭解更多命局知識>>',
    more_predict: '更多預測'
  },
  // 答案之书
  answer_book: {
    title: '答案之書預測',
    search: '搜索',
    search_holder: '蒐索答案之書標題',
    no_result: '未蒐索到相關結果',
    content:
      '答案之書——為你煩惱之事找到出路，\n覆蓋<span>情感、財運、事業、健康</span>等海量問題，\n你想問的都有解答。',
    history: '人測過',
    score: '分',
    start: '開始',
    start_tip: '準備好了點擊【開始】',
    stop: '停',
    question_tip: '請在心中默念問題…',
    tips: '準備好了點擊停止',
    check: '查看解讀',
    download: '<span>下載HiSeer APP</span>\n查看答案之書完整解讀',
    pay_prompt: '付费查看完整结果',
    tabs: ['推荐', '情感', '財運', '事業', '學業', '綜合'],
    save: {
      tip: '請仔細記錄您填寫的帳號，\n後續您可登入HiSeer app查看結果',
      title: '保存結果到HiSeer App',
      prompt:
        '不保存將會新增結果遺失的風險，保存後您可以使用驗證的帳號登入HiSeer app查看結果，是否保存？',
      success: [
        '1.打開HiSeer app，使用保存時輸入的郵箱或手機號登入，可查看已保存結果',
        '2.點擊本網頁右上角<span>☰</span>按鈕，在歷史記錄-訂單查詢頁面輸入訂單號/保存時提供的手機號或郵箱進行訂單查詢，可查看已保存結果'
      ]
    },
    more: '更多占卜',
    save_txt: '保存結果',
    paid: '人購買了占卜',
    useful: '的人覺得很准',
    loading: '載入中...',
    seer: '以上內容為HiSeer獨家研發的占卜服務'
  },
  // 资讯分享
  info: {
    share: '分享',
    public: '發佈',
    more: '更多推薦',
    download: '下載HiSeer app使用完整功能',
    prompt: '下載HiSeer app\n閱讀《》完整內容',
    read: '閱讀',
    replay: '重播',
    tipping: '打賞',
    commont: '評論',
    ivideo_download: '<span>下載HiSeer App</span>\n查看真人占卜完整解讀',
    write_comment: '寫下你的評論…',
    copyright: '原創占卜 禁止轉載抄襲',
    test: '人測過'
  },
  // 独立页面
  out: {
    master: {
      title: '專業諮詢師',
      desc: '1對1諮詢線上解答',
      list: [
        {
          bold: '專業：',
          content:
            '由HiSeer專業諮詢師提供1對1專業預測服務，所有諮詢師都經歷過專業的命理學學習，且有豐富的諮詢從業經驗，經由官方篩選、稽核，諮詢服務有保障。'
        },
        {
          bold: '多樣：',
          content:
            '擅長不同領域的諮詢師提供各類諮詢專案供用戶選擇，諮詢專案主要包括：命盤詳批、一生運勢、流年運勢、情感運勢、事業運勢、財富運勢、學業規劃、親子教育、感情合盤/合婚、六爻起卦、擇日、取名、面相等，多樣化諮詢服務滿足用戶需求。'
        },
        {
          bold: '靈活：',
          content:
            '通過HiSeer實时聊天工具、預測工具進行推演並答疑解惑，方便快捷。'
        }
      ]
    }
  }
}
