import { useRouter } from 'vue-router'
import i18n from '@/lang/index.js'
import {
  CURRENCY,
  CURRENCY_NAME,
  DEFAULT_COUNTRY,
  LANG,
  PAYTYPE,
  payBuryList
} from './contants.js'
import { path } from '@/router/constant.js'
import { lunar2solar, solar2lunar } from 'shiren-calendar'
import storage from '../utils/storage'
import { Toast } from 'vant'
import { getArea } from '@/apis/report.js'
// import { langList } from '@/lang/index.js'
export const langList = {
  koKR: 'ko',
  zhCN: 'sc',
  zhTW: 'tc'
}

const { t, locale } = i18n.global
// const router = useRouter()
//
// 获取操作系统信息
export function getOsInfo() {
  var userAgent = navigator.userAgent.toLowerCase()
  var name = 'Unknown'
  if (userAgent.indexOf('iphone') > -1 || userAgent.indexOf('mac') > -1) {
    name = 'ios'
  } else if (userAgent.indexOf('linux') > -1) {
    if (userAgent.indexOf('android') > -1) {
      name = 'android'
    } else {
      name = 'Linux'
    }
  } else {
    name = 'Unknown'
  }
  return name
}

export async function downloadSeer() {
  // 非 ios 根据区域下载apk
  if (getOsInfo() !== 'ios') {
    const {
      data: { country }
    } = await getArea()

    // const isLand =
    if (country === DEFAULT_COUNTRY) {
      location.href = 'https://d30h9ghwvozr8f.cloudfront.net/pro/apk/HiSeer.apk'
      return
    }
  }
  const url =
    getOsInfo() === 'ios'
      ? 'https://apps.apple.com/cn/app/seer%E7%A7%81%E4%BA%BA%E8%90%AC%E5%B9%B4%E6%9B%86/id1595739625'
      : 'https://d30h9ghwvozr8f.cloudfront.net/pro/apk/seer.apk'

  location.href = url
}

export function agreement() {
  const lang = langList[i18n.global.locale.value] || 'sc'
  return {
    user: `https://kapi.seerlegend.com/protocol/${lang}/agreement.html?s=h5`,
    secret: `https://kapi.seerlegend.com/protocol/${lang}/secret.html?s=h5`
  }
}

const numStr = '零一二三四五六七八九十'
export function number2Char(year) {
  console.log(year)
  const ret = year
    .toString()
    .split('')
    .map((_) => numStr[_])
    .join('')
  return ret
}

export function lunarDay(day) {
  if (day === 20) return '二十'
  if (day === 30) return '三十'
  // const str = ['初',]
  if (day <= 10) {
    return '初' + numStr[day]
  }
  if (day > 20) {
    return '廿' + number2Char(day - 20)
  }

  return '十' + numStr[day - 10]
}

export function lunarMonth(month) {
  console.log(month)
  const monthStr = '零正二三四五六七八九十冬腊'
  const isRun = isNaN(parseInt(month))
  const idx = isRun ? parseInt(month.slice(1)) : parseInt(month)
  const monthChar =
    idx === 12 && i18n.global.locale.value === 'zhTW' ? '臘' : monthStr[idx]
  return isRun ? t('extra') + monthChar : monthChar
}

export function lunarTosolar(year, month, day) {
  const _mon = isNaN(parseInt(month))
    ? parseInt(month.slice(1))
    : parseInt(month)
  const [y, m, d] = lunar2solar(year, _mon, day, isNaN(parseInt(month)))
  return `${y}-${m.toString().padStart(2, 0)}-${d.toString().padStart(2, 0)}`
}

// 后端接口数据格式化
export function solarTolunar(year, month, day) {
  const _mon = parseInt(month)
  const [y, m, d] = solar2lunar(year, _mon, day)
  return `${y}-${m.toString().padStart(2, 0)}-${d.toString().padStart(2, 0)}`
}

// window.solar2lunar = solar2lunar
// 前端兼容闰月格式化
export function solarTolunarExtra(year, month, day) {
  const _mon = parseInt(month)
  const [y, m, d, extra] = solar2lunar(year, _mon, day)
  console.log(extra)
  return `${y}-${extra ? t('extra') : ''}${m}-${d.toString().padStart(2, 0)}`
}

// 判断闰年
export function isExtraYear(year) {
  const _year = parseInt(year)
  return (_year % 4 === 0 && _year % 100 !== 0) || _year % 400 === 0
}

export function getSearch() {
  return (
    location.search &&
    location.search
      .substring(1)
      .split('&')
      .reduce((prev, _) => {
        const [key, value] = _.split('=')
        return { ...prev, [key]: value }
      }, {})
  )
}

// 防抖
export function debounce(fn, time = 300) {
  let timer = null
  return () => {
    if (!timer) timer = setTimeout(fn, time)
    else {
      clearTimeout(timer)
      timer = setTimeout(fn, time)
    }
  }
}
// 节流
export function throttling(fn, time = 300) {
  let timer = null
  return () => {
    if (!timer)
      timer = setTimeout(() => {
        fn()
        timer = null
      }, time)
  }
}

// query: {[name]: value}
export function getUrl(base, path, query = {}) {
  const search = Object.entries(query).reduce((prev, item) => {
    const [key, value] = item
    if (prev.length === 0) return `?${key}=${value}`

    return `${prev}&${key}=${value}`
  }, '')
  return new URL(path + search, base).href
}

export function updateMeta(key) {
  const ogDesc = document.querySelector('meta[property="og:description"]')
  const desc = document.querySelector('meta[name="description"]')
  const text = t(key)
  ogDesc.content = text
  desc.content = text
}

export function copyByCommand(content, toast) {
  const oInput = document.createElement('textarea')
  oInput.value = content
  oInput.readOnly = 'readOnly'
  document.body.appendChild(oInput)
  oInput.select() // 选择对象
  if (document.execCommand('copy')) {
    document.execCommand('copy')
  }
  oInput.blur()
  document.body.removeChild(oInput)
  // Toast(t('share.copy_success'))
  toast()
}

export function isLang(lang) {
  return locale.value === lang
}

export function scrollVisible(parentSelector, selector) {
  const parentEl = document.querySelector(parentSelector)
  const el = document.querySelector(selector)
  const parentRect = parentEl.getBoundingClientRect()
  const elRect = el.getBoundingClientRect()
  if (elRect.bottom < parentRect.top) return true
  return false
}

// 支付方式成功埋点方法
export function payTypeBury(reportType) {
  const { type } = getSearch()
  if (!type) return
  const buryList = payBuryList[type]
  if (!buryList) return
  const buryKey = buryList[reportType]

  gtag('event', buryKey)
}

export function openApp() {
  // 微信内跳转
  if (isWechat() || isTikTok()) {
    location.href = new URL(path.linkApp, import.meta.env.VITE_DOMAIN).href
    return
  }
  // ios 直接拉起app store
  if (getOsInfo() === 'ios') return downloadSeer()

  const appUrl = 'hiseer://com.shangcheong.myseer/'
  const aEl = document.createElement('a')
  aEl.href = appUrl
  // aEl.target = '_blank'
  document.body.append(aEl)
  aEl.click()
  document.body.removeChild(aEl)

  const toDownload = setTimeout(() => {
    if (document.visibilityState !== 'hidden') downloadSeer()
  }, 5000)

  const cancelDownload = () => {
    clearTimeout(toDownload)
    document.removeEventListener('visibilitychange', cancelDownload)
  }
  document.addEventListener('visibilitychange', cancelDownload)
}

export function isWechat() {
  return !!window.navigator.userAgent.toLowerCase().match(/micromessenger/i)
}

export const isTikTok = () =>
  /bytedance/i.test(navigator.userAgent.toLowerCase())

export const unixtimestamp2Date = (timestamp) => {
  if (isNaN(timestamp)) return ''
  const date = new Date(timestamp * 1000)
  return `${date.getFullYear().toString().padStart(2, 0)}年${(
    date.getMonth() + 1
  )
    .toString()
    .padStart(2, 0)}月${date.getDate().toString().padStart(2, 0)}日 ${date
    .getHours()
    .toString()
    .padStart(2, 0)}:${date.getMinutes().toString().padStart(2, 0)}`
}

export const image2Base64 = (url, selector, options) =>
  new Promise((resolve) => {
    const image = new Image()
    image.onload = () => {
      try {
        const { dx = 0, dy = 0, height, width } = options || {}
        const el = document.querySelector(selector)
        const h = height || el.clientHeight
        const w = width || el.clientWidth
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        const ratio =
          (window.devicePixelRatio || 1) /
          (ctx.backingStorePixelRatio ||
            ctx.webkitBackingStorePixelRatio ||
            ctx.mozBackingStorePixelRatio ||
            ctx.msBackingStorePixelRatio ||
            ctx.oBackingStorePixelRatio ||
            ctx.backingStorePixelRatio ||
            1)

        canvas.width = w * ratio
        canvas.height = h * ratio
        ctx.drawImage(
          image,
          dx * ratio,
          dy * ratio,
          (height || w) * ratio,
          (width || h) * ratio
        )
        resolve(canvas.toDataURL('image/png', 1))
      } catch (err) {
        console.log(err)
      }
    }
    image.onerror = (err) => {
      console.log(err)
      image.src = `${url.split('?')[0]}?${new Date().getTime()}`
    }
    image.crossOrigin = 'anonymous'
    image.src = url
  })
